import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import LoadingLogo from '../../components/LoadingLogo';
import Layout from '../../layout/CompanyLayout';
import { AppState } from '../../reducers';
import JobRequestView from '../../views/company/JobRequest/JobRequestView';

const JobRequestPage = (): React.ReactElement => {
  const company = useSelector((appState: AppState) => appState.company);
  useEffect(() => {
    if (company.verification?.status === 'DENIED') {
      setTimeout(() => {
        // navigate('/company/verification');
        return <LoadingLogo />;
      });
    }
    if (company.verification?.status === undefined) {
      setTimeout(() => {
        // navigate('/company/verification');
        return <LoadingLogo />;
      });
    }
    if (company.verification?.status === 'SUBMITTED') {
      setTimeout(() => {
        navigate('/company');
        return <LoadingLogo />;
      });
    }
  });
  return (
    <Layout>
      <JobRequestView />
    </Layout>
  );
};

export default JobRequestPage;
