import React from 'react';
import { Paper, Theme, Typography } from '@material-ui/core';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      width: '80%',
      margin: 'auto',
      paddingTop: theme.spacing(1),
    },
  });

export const JobRequestView = ({ classes }: Props): React.ReactElement => {
  return (
    <div className={classes.root}>
      <Typography component={Paper} align="center">
        Welcom to Fintegrity JobRequest Page
      </Typography>
    </div>
  );
};

type NewType = WithStyles<typeof styles>;

export type Props = NewType;

export default withStyles(styles)(JobRequestView);
